import { createI18n } from 'react-router-i18n';

// Array of supported locales
// The first in the array is treated as the default locale
const locales = ['en', 'th'];

// Dictionary of translations
const translations = {
    en: {
        lang:'en',
        menu: {
          home: "Home",
          about: "About us",
          shop: "Shop",
          meaning: "Stone Meaning",
          contact: "Contact",
        },
        shop_now: "Shop Now",
        explore_more: "Explore More",
        hinhinn_looks: "Hinhinn's Looks",
        stone: "Stone",
        meaning: "Meaning",
        followus: "Follow Us",
        slogan: "Natural Stone, Bracelace & Neckalace",
        about: "From the fascination with natural stones with different colors, patterns, uniqueness and meanings. Go to a small handmade jewelry shop that wants to pass on the beauty of nature's colors. and empowering the confidence of the person who wears it",
        cookiePolicy:
          "This website uses cookies. We use cookies to offer you a better browsing experience, analyze site traffic and personalize content.",
        accept: "Accept",
        more_detail_click: "More Detail Click",
        baht: "Baht",
        see_more: "See More",
        personal_beliefs: "Based on personal beliefs, decision discretion required.",
        buy: "Buy",
        back_main_page: "Back to main page"
      },
      th: {
        lang:'th',
        menu: {
          home: "หน้าหลัก",
          about: "เกี่ยวกับเรา",
          shop: "ร้านค้า",
          meaning: "ความหมายของหิน",
          contact: "ติดตามเรา",
        },
        shop_now: "ซื้อเลย",
        explore_more: "ดูเพิ่มเติม",
        hinhinn_looks: "สินค้าของ Hinhinn",
        stone: "หิน",
        meaning: "ความหมาย",
        followus: "Follow Us",
        slogan: "สร้อยข้อมือและสร้อยคอ หินธรรมชาติ",
        about: "จากความหลงไหลในหินธรรมชาติ ที่มีสี ลาย เอกลักษณ์ และความหมายแตกต่างกัน ไปสู่ร้านเครื่องประดับ handmade เล็กๆ ที่ต้องการส่งต่อความสวยงามจากสีสันของธรรมชาติ และเสริมพลังความมั่นใจจากความหมายให้ผู้ที่ได้สวมใส่",
        cookiePolicy:
          "เพื่อแจ้งถึงการใช้เว็บไซต์คุกกี้เพื่อเก็บรวบรวม และประมวลผลข้อมูลส่วนบุคคลของผู้ใช้งานของคุณ ตามกฎหมาย",
        accept: "ยอมรับ",
        more_detail_click: "คลิกดูรายละเอียด",
        baht: "บาท",
        see_more: "ดูเพิ่มเติม",
        personal_beliefs: "ความเชื่อส่วนบุคคล โปรดใช้วิจารณญาณ",
        buy: "สนใจซื้อ",
        back_main_page: "กลับสู่หน้าหลัก"
      },
}

const I18n = createI18n(
  locales,
  translations,
);

export default I18n;