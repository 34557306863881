import React from "react";

function Header() {

    return (
        <header
          id="masthead"
          className={`site-header active`}
          style={{
            background: `rgba(193, 166, 153, 1)`,
            padding: `${30 - 30 * 1}px 0px`,
          }}
        >
            <nav id="primary-navigation" className="site-navigation">
            <div className="container">
              <div className="navbar-header page-scroll">
                <span className="site-logo" >
                  Hinhinn
                </span>
              </div>
            </div>
            </nav>
        </header>
    );
}

export default Header;