import React, { useRef, useState, useEffect } from "react";
import ScrollReveal from "scrollreveal";
import Products from "./Products";
import Stones from "./Stones";
import ProductInfo from "./ProductInfo";
import Footer from "./partials/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLine,
  faFacebookMessenger,
} from "@fortawesome/free-brands-svg-icons";

import TagManager from 'react-gtm-module';
import { useCookies } from 'react-cookie';

import I18n from './I18n';

let heroHeight = 0;

function Main({ location }) {
    const [cookies, setCookie] = useCookies(['accepted-cookie-policy']);
    const [acceptCookiePolicy, setAcceptCookiePolicy] = useState(false)
    const handleAcceptCookiePolicy = () => {
      setAcceptCookiePolicy(true)
      setCookie('accepted-cookie-policy', true, { expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
    }
    const [lang, setLang] = useState("en");
    const [navActive, setNavActive] = useState(false);
    const [modalProduct, setModalProduct] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [loading, setLoading] = useState(true);
    const [imageHeroLoaded, setImageHeroLoaded] = useState(false);
    const [imageMBHeroLoaded, setImageMBHeroLoaded] = useState(false);
  
  
    const tagManagerArgs = {
      gtmId: 'GTM-NN5LX63'
    }
   
    TagManager.initialize(tagManagerArgs)
  
    const homeRef = useRef(null);
    const goToHome = () =>
      homeRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    const aboutRef = useRef(null);
    const goToAbout = () =>
      aboutRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    const shopRef = useRef(null);
    const goToShop = () =>
      shopRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    const meaningRef = useRef(null);
    const goToMeaning = () =>
      meaningRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    const contactRef = useRef(null);
    const goToContact = () =>
      contactRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
  
    const [progress, setProgress] = useState(0);
  
    const calculateHeroHeight = () => {
      if (!homeRef || !homeRef.current) return;
      heroHeight = homeRef.current.clientHeight;
    };
  
    const handleLang = (selectedLang) => {
      if (selectedLang === lang) return;
      setLang(selectedLang);
      goToHome();
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const scrolled = window.scrollY;
        if (scrolled > heroHeight && progress === 1) return;
        const result = scrolled / heroHeight;
        if (result >= 1) return setProgress(1);
        setProgress(result);
      };
  
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", calculateHeroHeight);
      calculateHeroHeight();
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", calculateHeroHeight);
      };
    }, [progress]);
  
    useEffect(() => {
      const html = document.querySelector('html');
      if(modalProduct || loading) {
        html.classList.add('opened');
      } else {
        html.classList.remove('opened');
        setSelectedProduct({});
      }
    }, [modalProduct, loading]);
  
    useEffect(() => {
      var slideUp = {
        distance: "100%",
        origin: "bottom",
        duration: 1000,
        delay: 100,
        opacity: 0,
      };
      ScrollReveal().reveal(".section-about", slideUp);
  
      const path = location.pathname;
      if(path !== "/"){
          setLang(path.replace("/", ""));
          setLoading(false);
      } else {
          fetch(`https://geolocation-db.com/json/${'5478e9a0-e6ed-11eb-b435-8d4a35577878'}`, { mode: "cors" })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
            throw new Error(`Request error. ${res.status}`);
          })
          .then(res => {
              if(res.country_code === "TH"){
                  document.querySelector('.goToTH').click();
                  setLang("th");
              }
              setLoading(false);
          })
          .catch(e => {
            throw new Error(`Cannot fetch country from Geolocation DB. ${e}`);
          });
      }
  
    }, [location])
  
    useEffect(() => {
      if(cookies['accepted-cookie-policy'])
      setAcceptCookiePolicy(true)
  
    }, [cookies])

    const handleHeroImageLoaded = () => {
      setImageHeroLoaded(true);
    }

    const handleMBHeroImageLoaded = () => {
      setImageMBHeroLoaded(true);
    }
  
    return (
      <>
        {!acceptCookiePolicy && (
          <div className="cookie-policy">
            <span><I18n t="cookiePolicy" /></span>
            <button onClick={() => handleAcceptCookiePolicy()}>
              <I18n t="accept" />
            </button>
          </div>
        )}
  
        <header
          id="masthead"
          className={`site-header ${progress === 1 ? "active" : ""}`}
          style={{
            background: `rgba(193, 166, 153, ${progress})`,
            padding: `${30 - 30 * progress}px 0px`,
          }}
        >
          <nav id="primary-navigation" className="site-navigation">
            <div className="container">
              <div className="navbar-header page-scroll">
                <button
                  type="button"
                  className={`navbar-toggle collapsed ${navActive ? "act" : ""}`}
                  onClick={() => setNavActive(!navActive)}
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
  
                <span className="site-logo" onClick={goToHome}>
                  Hinhinn
                </span>
              </div>
  
              <div
                className={`main-menu ${navActive ? "act" : ""}`}
                id="products-perfect-collapse act"
              >
                <ul className="nav navbar-nav navbar-right">
                  <li className="page-scroll">
                    <span
                      onClick={() => {
                        setNavActive(false);
                        goToHome();
                      }}
                    >
                      <I18n t="menu.home" />
                    </span>
                  </li>
                  <li className="page-scroll">
                    <span
                      onClick={() => {
                        setNavActive(false);
                        goToAbout();
                      }}
                    >
                      <I18n t="menu.about" />
                    </span>
                  </li>
                  <li className="page-scroll">
                    <span
                      onClick={() => {
                        setNavActive(false);
                        goToShop();
                      }}
                    >
                      <I18n t="menu.shop" />
                    </span>
                  </li>
                  <li className="page-scroll">
                    <span
                      onClick={() => {
                        setNavActive(false);
                        goToMeaning();
                      }}
                    >
                      <I18n t="menu.meaning" />
                    </span>
                  </li>
                  <li className="page-scroll">
                    <span
                      onClick={() => {
                        setNavActive(false);
                        goToContact();
                      }}
                    >
                      <I18n t="menu.contact" />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div id="hero" className="hero" ref={homeRef}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>Hinhinn</h1>
                <div className="page-scroll">
                  <p className="job-title"><I18n t="slogan" /></p>
                  <span className="btn btn-fill " onClick={goToShop}>
                    <I18n t="shop_now" />
                  </span>
                  <div className="clearfix visible-xxs"></div>
                  <span className="btn btn-border" onClick={goToAbout}>
                      <I18n t="explore_more" />
                  </span>
                </div>
              </div>
  
              <div className="col-md-6 text-right">
                <img className={`img ${imageHeroLoaded? 'loaded':''}`} src="/images/hero-trans.png" alt="hinhinn-hero" onLoad={handleHeroImageLoaded} />
                <img className={`img-mb ${imageMBHeroLoaded? 'loaded':''}`} src="/images/hero-mb-trans.png" alt="hinhinn-hero" onLoad={handleMBHeroImageLoaded} />
              </div>
            </div>
          </div>
        </div>
        <main id="main" className="site-main">
          <section
            id="about"
            ref={aboutRef}
            className="site-section section-about text-center"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-md-offset-3">
                  <h2><I18n t="menu.about" /></h2>
                  <div className="img-lines" alt="lines"></div>
                  <p><I18n t="about" /></p>
                </div>
              </div>
            </div>
          </section>
  
          {/* section products */}
          <Products lang={lang} shopRef={shopRef} setSelectedProduct={setSelectedProduct} setModalProduct={setModalProduct} />

          {/* <!-- /.secton-meaning --> */}
          <Stones lang={lang} meaningRef={meaningRef}/>
  
          <section
            ref={contactRef}
            id="contact"
            className="site-section section-form text-center"
          >
            <div className="container">
              <h3><I18n t="followus" /></h3>
              <div className="img-lines" alt="lines"></div>
              <div className="social-icon">
                <a
                  className="icon instagram-bg"
                  href="https://www.instagram.com/hinhinn.official"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  className="icon facebook-bg"
                  href="https://www.facebook.com/hinhinn.official"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a
                  className="icon line-bg"
                  href="https://lin.ee/WNBJ9MI"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faLine} />
                </a>
                <a
                  className="icon email-bg"
                  href="mailto:hinhinn.officail@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </div>
            </div>
          </section>
          {/* <!-- /.section-form --> */}
        </main>
        <Footer lang={lang} handleLang={handleLang} />
  
        <ProductInfo product={selectedProduct} modalProduct={modalProduct} setModalProduct={setModalProduct} />
        <div className={`loader-holder ${loading? "":"loader-hide"}`}>
          <div className="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
  
      </>
    );
  }
  
  export default Main;