import React from "react";
import I18n from './I18n';
import Header from "./partials/Header";
import { Link } from "react-router-dom";

function ProductDetail({ location, match }) {
    
    return (
        <>
        <Header />
        <div className="p-detail">
            <I18n t="menu.shop" />
            Test { match.params.productSlug }
            <Link to="/">Home</Link>
        </div>
        </>
    );
}

export default ProductDetail;