import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"
import I18n from './I18n';

// import Swiper core and required modules
import SwiperCore, {
  Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation]);


const Stones = ({ lang, meaningRef }) => {
  const stones = {
    en: [
      {
        name: "Howlite",
        desc: "Stone of emotion, Calm down, Treat insomnia and Reduce stress.",
        image: "/stones/howlite.png",
      },
      {
        name: "Sun Stone",
        desc: "Symbol of growth, prosperity, bright like the light of the sun. Help awaken energy and build confidence.",
        image: "/stones/sunstone.png",
      },
      {
        name: "Gray Tiger’s Eye",
        desc: "Summon wealth, increase financial luck.",
        image: "/stones/graytiger.png",
      },
      {
        name: "Gray Quartz",
        desc: "Balancing stones help to energize the chakras in various points of the body. Get rid of negative feelings",
        image: "/stones/grayqurzt.png",
      },
      {
        name: "Onyx",
        desc: "Protection from danger, Reduce stress, Anxiety and Solving people who are sick often.",
        image: "/stones/onyx.png",
      },
      {
        name: "Volcanic",
        desc: "The best of \"Ignition\" stimulates the energy to create new things, create positive visions and lead the wearer on the right path.",
        image: "/stones/lava.png",
      },
      // {
      //   name: "Picasso Jasper",
      //   desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      //   image: "/stones/graytiger.png",
      // },
      {
        name: "Gold Obsidian",
        desc: "Attracts financial fortune and property, drives away bad things.",
        image: "/stones/goldobsidian.png",
      },
      {
        name: "Rose Quartz",
        desc: "Stone of Venus. Full of the power of love to have a good and stable love.",
        image: "/stones/rosequartz.png",
      },
      {
        name: "Pink Aventurine",
        desc: "Enhance luck, Risk luck, Helps to increase the power of imagination and creativity. Including the love of the people around.",
        image: "/stones/pinkavengerine.png",
      },
      {
        name: "Mixed Tiger's Eye",
        desc: "Increase attractiveness, reduce stubbornness, anger, stubbornness, as well as prevent you from occultism and cure it.",
        image: "/stones/mixedtiger.png",
      },
      {
        name: "Hematite",
        desc: "Courageous, strong, a stone that gives strength to the wearer.",
        image: "/stones/hermatite.png",
      },
      {
        name: "Pyrite",
        desc: "Pyrite stone is a symbol of wealth and good luck.",
        image: "/stones/pyrite.png",
      },
      {
        name: "Turquoise",
        desc: "Stone of wisdom, Empower and Ancient believes that it is a sacred stone that brings good luck.",
        image: "/stones/turquoise.png",
      },
    ],
    th: [
      {
        name: "ฮาวไลท์",
        desc: "หินแห่งอารมณ์ ช่วยให้ใจเย็น บำบัดอาการนอนไม่หลับ ลดความเครียด",
        image: "/stones/howlite.png",
      },
      {
        name: "ซันสโตน",
        desc: "หินแห่งดวงอาทิตย์ สัญลักษณ์ของการเจริญเติบโต รุ่งเรือง สว่างสดใสเหมือนแสงของดวงอาทิตย์ ช่วยปลุกพลังงาน สร้างความเชื่อมั่น",
        image: "/stones/sunstone.png",
      },
      {
        name: "ตาเสือเทา",
        desc: "เรียกทรัพย์ เพิ่มโชคด้านการเงิน",
        image: "/stones/graytiger.png",
      },
      {
        name: "เกรย์ ควอทซ์",
        desc: "หินปรับสมดุล ช่วยให้พลังงานจักระตามจุดต่างๆของร่างกาย ขจัดความรู้สึกด้านลบ",
        image: "/stones/grayqurzt.png",
      },
      {
        name: "นิล",
        desc: "คุ้มครองจากภัยอันตราย ลดความเครียดวิตกกังวล แก้เคล็ดคนที่ป่วยบ่อยๆ",
        image: "/stones/onyx.png",
      },
      {
        name: "ลาวา",
        desc: 'ที่สุดแห่งการ "จุดประกายความคิด" จะช่วยกระตุ้นพลังในการสร้างสรรค์สิ่งใหม่ ๆ สร้างวิสัยทัศน์ที่ดี และนำพาผู้สวมใส่ไปสู่เส้นทางที่ถูกต้อง',
        image: "/stones/lava.png",
      },
      // {
      //   name: "Picasso Jasper",
      //   desc: "หินแห่งความนุ่มนวล สร้างสมดุล เสริมความคิดสร้างสรรค์ จิตใจสงบ ลความเครียด",
      //   image: "/stones/graytiger.png",
      // },
      {
        name: "ออบซิเดียน สีทอง",
        desc: "ดึงดูดโชคลาภทางการเงิน และทรัพย์สิน ขับไล่สิ่งไม่ดี",
        image: "/stones/goldobsidian.png",
      },
      {
        name: "โรสควอทซ์",
        desc: "หินแห่งเทพวีนัส เต็มเปี่ยมไปด้วยพลังแห่งความรัก ให้มีความรักที่ดีมั่นคง",
        image: "/stones/rosequartz.png",
      },
      {
        name: "พิ้งค์ อเวเจอรีน",
        desc: "เสริมโชคลาภการเสี่ยงดวง ช่วยเพิ่มพลังแห่งจินตนาการและความคิดสร้างสรรค์ รวมถึงความเป็นที่รักของคนรอบข้าง",
        image: "/stones/pinkavengerine.png",
      },
      {
        name: "สามทหารเสือ",
        desc: "เพิ่มเสน่ห์ดึงดูด ลดทิฐิ ความโกรธ ดื้อดึง รวมทั้งยังป้องกันคุณไสยและแก้ชง",
        image: "/stones/mixedtiger.png",
      },
      {
        name: "เฮมาไทต์",
        desc: "กล้าหาญ หนักแน่น เป็นหินที่ให้พลังแก่ผู้ใส่",
        image: "/stones/hermatite.png",
      },
      {
        name: "ไพไรต์",
        desc: "หินนางกวัก เรียกเงินทอง สัญลักษณ์แห่งทรัพย์สินเงินทองและความโชคดี",
        image: "/stones/pyrite.png",
      },
      {
        name: "เทอร์คอยส์",
        desc: "หินแห่งภูมิปัญญา เสริมพลังอำนาจ โบราณเชื่อว่าเป็นหินศักดิ์สิทธิ์ นำโชค",
        image: "/stones/turquoise.png",
      },
    ],
  };


  const stoneContents = () => {
    return stones[lang]?.map((s, i) => (
      <SwiperSlide key={`stone-${i}`}>
        <div className="slide-col" >
          <div className="meaning">
            <div
              className="meaning-img"
              style={{ backgroundImage: `url(${s.image})` }}
            ></div>
            <h4>{s.name}</h4>
            {lang === "th" && <h5>{stones["en"][i].name}</h5> }
            <p>{s.desc}</p>
          </div>
          {/* <!-- /.meaning --> */}
        </div>
      </SwiperSlide>
    ));
  };

  return (
    <section
      ref={meaningRef}
      id="meaning"
      className="site-section section-meaning overlay text-center"
    >
      <div className="container">
      <div className="row">
          <div className="col-md-12">
            <span><I18n t="stone" /></span>
            <h3><I18n t="meaning" /></h3>
            <div className="img-lines" alt="lines"></div>
          </div>
        </div>
        <Swiper 
          navigation={true} 
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
            },
            // when window width is >= 991px
            991: {
              slidesPerView: 3,
            }
          }}
          spaceBetween={0} 
        className="stoneSwiper">
        {stoneContents()}
        </Swiper>
        <p className="beliefs">*<I18n t="personal_beliefs" />*</p>
      </div>
    </section>
  );
};

export default Stones;
