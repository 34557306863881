import React, { useState, useEffect } from "react";
import I18n from "./I18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "./utils/Skeleton";

function ProductInfo({ product, modalProduct, setModalProduct }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (!modalProduct) {
      setImageLoaded(false);
    }
  }, [modalProduct]);

  return (
    <>
      {/* {modalProduct && <RemoveScroll></RemoveScroll>} */}
      <div className={`collection-info ${modalProduct ? "active" : ""}`}>
        <div className="collection-holder">
          <div className="col-detail img loading_holder">
            <div className={`image ${imageLoaded ? "loaded" : ""}`} >
            { modalProduct && !imageLoaded && <Skeleton /> }
            <img
              src={`/products/${product.image}`}
              onLoad={() => setImageLoaded(true)}
            />
            </div>
          </div>
          <div className="mb-action">
            <button className="buy-btn">
              <I18n t="buy" />
            </button>
            <div className="info-off" onClick={() => setModalProduct(false)}>
              <FontAwesomeIcon icon={faLongArrowAltLeft} />{" "}
              <I18n t="back_main_page" />
            </div>
          </div>
          <div className="col-detail info">
            {modalProduct && (
              <div className="detail">
                <h1 className="detail-title">{product?.name}</h1>
                <p dangerouslySetInnerHTML={{ __html: `${product?.desc}` }}></p>
                <span className="detail-price">
                  {product?.price} <I18n t="baht" />
                </span>

                <button className="buy-btn">
                  <I18n t="buy" />
                </button>
                <div
                  className="info-off"
                  onClick={() => setModalProduct(false)}
                >
                  <FontAwesomeIcon icon={faLongArrowAltLeft} />{" "}
                  <I18n t="back_main_page" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <style>
        {`
                ::-webkit-scrollbar {
                  width: 0;  /* Remove scrollbar space */
                  background: transparent;  /* Optional: just make scrollbar invisible */
              }
                .collection-info {
                    position: fixed;
                    z-index: -9;
                    opacity: 0;
                    transition: opacity 0.5s, z-index 0.5s;
                    will-change: opacity;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    overflow-y: scroll;
                    overflow-x: hidden;
                    -webkit-overflow-scrolling: touch;
                    background: var(--color-white);
                }

                .collection-holder {
                  display: flex;
                  margin: auto 0;
                  width: 100%;
                }

                .collection-info.active {
                    opacity: 1;
                    z-index: 99;
                    transition: opacity 0.5s, z-index 0.5s;
                }

                .collection-info .col-detail {
                    display: inline-block;
                    width: 50%;
                    height: 100vh;
                }

                .collection-info .col-detail.img {
                    background-color: rgb(193 166 153 / 90%);
                    backdrop-filter: saturate(180%) blur(5px);
                    transform: translate3d(0, -100%, 0);
                    transition: transform 0.2s ease-in;
                }

                .collection-info.active .col-detail.img {
                    z-index: 9;
                    transform: translate3d(0, 0, 0);
                    transition: transform 0.2s ease-out;
                }

                .collection-info .col-detail.img .image{
                    width: 500px;
                    height: 400px;
                    position: absolute;
                    top: 30%;
                    right: -50px;
                    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
                }

                .collection-info .col-detail.img .image img{
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                  top: 0;
                  left: 0;
                  opacity: 0;
                  transition: opacity .2s;
                }

                .collection-info .col-detail.img .image.loaded img{
                  opacity: 1;
                }

                .collection-info .col-detail.info {
                    background-color: var(--color-white);
                    transform: translate3d(0, 200%, 0);
                    transition: transform 0.3s ease-in;
                }

                .collection-info.active .col-detail.info {
                    display: flex;
                    justify-content: center;
                    color: var(--color-hinhinn-dark);
                    transform: translate3d(0, 0, 0);
                    transition: transform 0.3s ease-out;
                }

                .collection-info .col-detail.info .detail {
                    margin: auto;
                    width: 400px;
                }

                .detail-price {
                    display: block;
                    font-size: 22px;
                    margin-bottom: 20px;
                }

                .buy-btn {
                    font-weight: bold;
                    text-transform: uppercase;
                    color: var(--color-hinhinn);
                    background-color: var(--color-white);
                    border: 1px solid;
                    width: 200px;
                    line-height: 1;
                    padding: 10px 0px;
                    margin: 20px 0px;
                    letter-spacing: 2px;
                    box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 5%) 0px 4px 6px -2px;
                    transition: background-color .3s, color .3s, letter-spacing .3s;
                    
                }

                .buy-btn:hover,
                .buy-btn:focus {
                    // box-shadow: inset 200px 0 0 0 var(--color-hinhinn), rgb(0 0 0 / 5%) 0px 4px 6px -2px;
                    // border-color: var(--color-hinhinn);
                    color: var(--color-white);
                    letter-spacing: 3px;
                    background-color: var(--color-hinhinn);
                }

                .info-off {
                    cursor: pointer;
                    opacity: .6;
                    transition: opacity 0.3s,
                }
                .info-off:hover {
                    opacity: 1;
                }

                .mb-action {
                  display: none;
                }

                @media only screen and (max-width : 1199px) {
                    .collection-info.active {
                        display: block;
                    }
                    .collection-info  .col-detail{
                        min-width: 100%;
                        display: block;
                    }

                    .collection-info  .col-detail.img{
                        position: relative;
                        height: 30vh;
                        min-height: 300px;
                    }

                    .collection-info .col-detail.img .image {
                        max-width: 100%;
                        width: 500px;
                        height: 400px;
                        top: 10%;
                        right: unset;
                        margin: auto;
                        display: block;
                        position: relative;
                    }

                    .collection-info  .col-detail.info{
                        height: auto;
                    }
                }

                @media only screen and (max-width : 900px) {
                  .collection-holder {
                    display: block;
                  }

                  .collection-info .col-detail.info .buy-btn, 
                  .collection-info .col-detail.info .info-off {
                      display: none;
                    }
                    .collection-info .col-detail.img .image {
                        top: unset;
                        max-height: 340px;
                        background-color: var(--color-hinhinn);
                    }

                    .collection-info .col-detail.info .detail {
                      width: 80%;
                      margin: 0px;
                      margin-top: 110px;
                      text-align: center;
                    }

                    .mb-action {
                      position: absolute;
                      display: block;
                      width: 100%;
                      z-index: 99;
                    }

                    .mb-action .buy-btn {
                      margin: auto;
                      margin-top: 20px;
                      display: block;
                    }

                    .mb-action .info-off {
                      margin-top: 20px;
                      text-align: center;
                      color: var(--color-hinhinn-dark);
                    }
                }
            
            `}
      </style>
    </>
  );
}

export default ProductInfo;
