import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import LazyLoad from "react-lazy-load";
import I18n from "./I18n";

const Products = ({ lang, shopRef, setSelectedProduct, setModalProduct }) => {
  const products = {
    en: [
      {
        code: "H101",
        name: "Gold Obsidian x Picasso Jasper",
        price: 450,
        desc: "Natural stone bracelet Black with gold flecks, punctuated by Picasso Jasper stones for added color and hematite inserts on the front.",
        image: "H101.jpg",
        link: "",
      },
      {
        code: "H102",
        name: "Gray Quartz",
        price: 490,
        desc: "Gray Quartz's natural stone bangle gives a light and comfortable feeling. Add a touch of effect by separating the two sides of the hematite of the bracelet.",
        image: "H102.jpg",
        link: "",
      },
      {
        code: "H103",
        name: "Gold Obsidian",
        price: 400,
        desc: "Natural stone bracelet black gold Add effect by separating the front Pyrite.",
        image: "H103.jpg",
        link: "",
      },
      {
        code: "H105",
        name: "Gray Quartz x Volcanic",
        price: 490,
        desc: "Black natural stone bracelet, characterized by the pattern of Volcanic | Lava Stone, separated by Hematite and Gray Quartz (top).<br/><br/>The clear gray natural stone bracelet of Gray Quartz gives a light and comfortable feeling. Added effect by separating Hematite and Volcanic | Lava Stone (bottom).",
        image: "H105.jpg",
        link: "",
      },
      {
        code: "H104",
        name: "Sun Stone",
        price: 490,
        desc: "Natural stone bracelet Feels as bright as the light of the sun natural orange tone Add an iridescent effect by separating the two sides of the bracelet with Pyrite.",
        image: "H104.jpg",
        link: "",
      },
      {
        code: "H106",
        name: "Double Gray",
        price: 490,
        desc: "Gray Tiger's Eye Black Natural Stone Bracelet, cut with clear gray quartz and separated by Hematite.",
        image: "H106.jpg",
        link: "",
      },
    ],
    th: [
      {
        code: "H101",
        name: "Gold Obsidian x Picasso Jasper",
        price: 450,
        desc: "กำไลหินธรรมชาติ สีดำเหลือบทอง คั่นด้วยหิน Picasso Jasper เพื่อเพิ่มสีสันและแทรกด้วย Hematite ด้านหน้า",
        image: "H101.jpg",
        link: "https://www.facebook.com/commerce/products/4527571267255456",
      },
      {
        code: "H102",
        name: "Gray Quartz",
        price: 490,
        desc: "กำไลหินธรรมชาติ สีเทาใส ของ Gray Quartz ให้ความรู้สึกสว่างสบายตา เพิ่มลูกเล่นด้วยการคั่นของ Hematite 2 ด้านของกำไล",
        image: "H102.jpg",
        link: "https://www.facebook.com/commerce/products/5779405198796253",
      },
      {
        code: "H103",
        name: "Gold Obsidian",
        price: 400,
        desc: "กำไลหินธรรมชาติ สีดำเหลือบทอง เพิ่มลูกเล่นด้วยการคั่นของ Pyrite ด้านหน้า",
        image: "H103.jpg",
        link: "https://www.facebook.com/commerce/products/6278031848905540",
      },
      {
        code: "H105",
        name: "Gray Quartz x Volcanic",
        price: 490,
        desc: "กำไลหินธรรมชาติ สีดำ ลักษณะโดดเด่นด้วยลายของ Volcanic | Lava Stone คั่นด้วย Hematite และ Gray Quartz (บน) <br /><br /> กำไลหินธรรมชาติ สีเทาใส ของ Gray Quartz ให้ความรู้สึกสว่างสบายตา เพิ่มลูกเล่นด้วยการคั่นของ Hematite และ Volcanic | Lava Stone (ล่าง)",
        image: "H105.jpg",
        link: "https://www.facebook.com/commerce/products/4965776653449219",
      },
      {
        code: "H104",
        name: "Sun Stone",
        price: 490,
        desc: "กำไลหินธรรมชาติ ให้ความรู้สึกสดใสเหมือนแสงของดวงอาทิตย์ โทนสีส้มธรรมชาติ เพิ่มลูกเล่นสีเหลือบทองด้วยการคั่นของ Pyrite 2 ด้านของกำไล",
        image: "H104.jpg",
        link: "https://www.facebook.com/commerce/products/5581271168609851",
      },
      {
        code: "H106",
        name: "Double Gray",
        price: 490,
        desc: "กำไลหินธรรมชาติ สีดำเทาของ Gray Tiger’s Eye ตัดด้วยเทาใส Gray Quartz และคั่นด้วยหิน Hematite",
        image: "H106.jpg",
        link: "https://www.facebook.com/commerce/products/6096284223745621",
      },
    ],
  };

  const selectProduct = (product) => {
    setSelectedProduct(product);
    setModalProduct(true);
  };

  const productContents = () => {
    return products[lang]?.slice(0, 6).map((p, i) => (
      <div className="col-md-4 col-xs-6" key={`product-${i}`}>
        <div className="products-item" onClick={() => selectProduct(p)}>
          <LazyLoad offsetTop={200}>
            <img
              src={`/products/thub/${p.image}`}
              className="img-res"
              alt={p.name}
            />
          </LazyLoad>
          <div className="products-item-info">
            <h4>
              {p.name} <br />
              {p.price} <I18n t="baht" />
            </h4>
            <span onClick={() => selectProduct(p)}>
              <I18n t="more_detail_click" />
            </span>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <section
      id="products"
      ref={shopRef}
      className="site-section section-products"
    >
      <div className="container">
        <div className="text-center">
          <span>
            <I18n t="menu.shop" />
          </span>
          <h3>
            <I18n t="hinhinn_looks" />
          </h3>
          <div className="img-lines" alt="lines"></div>
        </div>
        <div className="row">{productContents()}</div>
        <div className="text-center">
          <a
            href="https://www.instagram.com/hinhinn.official"
            target="_blank"
            rel="noreferrer"
          >
            <I18n t="see_more" /> &nbsp;
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Products;
