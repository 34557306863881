import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Main from "./components/Main";
import ProductDetail from "./components/ProductDetail";

const base = "/:locale(en|th)?";
function App() {
  return (
    <div className="App">
    <Router>
      <Switch>
        <Route  path={`${base}/collections/:productSlug`} component={ProductDetail} />
        <Route exact path={base} component={Main} />
      </Switch>
    </Router>
    </div>
  );
}

export default App;
