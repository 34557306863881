import React from "react";

function Skeleton({ height, width }) {
  return (
    <>
        <div className="skeleton-box" ></div>
        <style>
            {`
            .skeleton-box {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                background-color: var(--color-hinnhinn);
              }
              .skeleton-box::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transform: translateX(-100%);
                background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
                -webkit-animation: shimmer 2s infinite;
                        animation: shimmer 2s infinite;
                content: "";
              }
              @-webkit-keyframes shimmer {
                100% {
                  transform: translateX(100%);
                }
              }
              @keyframes shimmer {
                100% {
                  transform: translateX(100%);
                }
              }
            `}
        </style>
    </>
  );
}

export default Skeleton;
