import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { NavLink } from 'react-router-i18n';
import { faLanguage } from "@fortawesome/free-solid-svg-icons";

function Footer({ lang, handleLang }) {

    return (
        <>
        <footer id="colophon" className="site-footer">
          <div className="container">
            <p className="copyright">
              <span className="lang-switch">
                <FontAwesomeIcon icon={faLanguage} />
                <NavLink ignoreLocale to={`/th`} className="goToTH">
                <span
                  className={lang === "th" ? "active" : ""}
                  onClick={() => handleLang("th")}
                >
                  {" "}
                  TH{" "}
                </span>
                </NavLink>
                &nbsp; | &nbsp;
                <NavLink ignoreLocale to={`/en`}>
                  <span
                      className={lang === "en" ? "active" : ""}
                      onClick={() => handleLang("en")} 
                  >
                      {" "}
                      EN{" "}
                  </span>
                  </NavLink>
              </span>
              © 2021 Hinhinn. All rights reserved.
            </p>
          </div>
        </footer>
        {/* <!-- /#footer --> */}
        <div>
          <MessengerCustomerChat
            pageId="101277868793771"
            appId="1511129759233165"
            themeColor="#C1A699"
          />
        </div>
        </>
    );
}

export default Footer;